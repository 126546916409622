<!--
 * @Author: lbh
 * @Date: 2021-04-23 18:16:52
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-24 15:20:42
 * @Description: 關於
-->
<template>
  <div
    class="products t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="about"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="Gingersoft 薑軟件"
        :type="1"
        :image="getImgPath('about_gingersoftBG.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        :logo="graphic.logo"
        :bgImg="graphic.bgImg"
        :but="graphic.but"
      />

    </div>
    <selfFooter :type="window.type" />
    <self-contact v-if="!isRicepon" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        logo: '',
        bgImg: '',
        but: {
          bgImg: '',
          img: '',
          title: '',
          content: ''
        }
      },
      isRicepon: false
    }
  },
  mounted () {
    util.setTitle('關於Gingersoft – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    let isRicepon = util.isRicepon();
    this.isRicepon = isRicepon;
    // 初始化數據
    this.graphic.logo = util.getImgPath('gingersolt_logo.png');
    this.graphic.bgImg = util.getImgPath('bg7-1.jpg');
    let imgs = [
      util.getImgPath('about_3.png')];
    this.graphic.imgs = imgs;

    let datas = [];
    if (isRicepon) {


      let one = {
        title: "公司簡介",
        items: [
          { title: 'Gingersoft 薑軟件 在香港成立於2012年，一直專注於餐飲業營運系統上，積極研發及推廣全面的餐飲系統軟件，致力為客戶提供優質的產品與服務，針對客戶不同的需要，提供最合適的方案。' },
          { title: '我們在深圳擁有自己的開發團隊，年輕的成員們都充滿著工作熱誠，亦十分重視團隊的合作精神，用心創造每一個可能。', style: 'margin-top:25px;' },
          { title: '', style: 'height:1px;background-color:#ddd;margin-top:25px;' }
        ]
      };

      let two = {
        title: '經營理念', items: [
          { title: '餐飲業面對租金高, 人工高, 食材高, 利潤低的3高1低痛點, 戰場方面更由實體店延伸到線上 戰場, 餐廳營運者更需要在激烈的戰局中提升競爭力，為解決市場痛點, 我們Gingersoft 的 產品設計師多年研發線上線下解決方案, 運用線上系統與線下系統無縫對接, 系統一體化令用 戶與餐廳用最簡單的方式溝通, 系統智能推薦令餐廳人員能夠提前了解客人需要, 作出針對性 銷售, 亦可分析餐廳營運佈局, 作出餐廳資源共享, 令進一步提升產能!' },
        ],
      }
      let three = {
        title: '', items: [
          { title: '●  系統碎片化 vs 系統一體化' },
          { title: '●  線上線下結合的用戶體驗' },
          { title: '●  功能服務鏈一脈相連' },
          { title: '●  智慧系統智能推薦' },
        ],
      }
      datas.push(one);
      datas.push(two);
      datas.push(three);
    } else {
      let one = {
        title: "公司簡介",
        items: [
          { title: 'Gingersoft 薑軟件在2012年於香港成立，多年來一直專注於研究及開發餐飲管理系統，擁有豐富的行業經驗及服務多所小型、大型及連鎖餐廳，致力為每位客戶提供客製化的智能餐飲管理方案，務求為客戶節省人力成本、提高經營效率！我們更擁有自己的研發團隊，能及時有效地因應社會發展需要為每位客戶設計出最合適的產品！' },
        ]
      }
      let two = {
        title: '經營理念', items: [
          { title: '清楚知道餐飲業面臨“租金高 人工高 食材成本高 利潤低”的三高一低痛點，因應全球發展趨勢各行各業的戰場亦從線下實體店已經延伸至線上外賣店。為了符合時代發展需要，創新推出雲端餐飲管理系統，結合掃碼落單及會員系統，助每位老闆從經營到營銷到營利！' },
        ],
      }
      datas.push(one);
      datas.push(two);
    }

    this.graphic.datas = datas;
    this.graphic.but.bgImg = util.getImgPath('gingersoft_web_bg02.jpg');
    this.graphic.but.img = util.getImgPath('14631-Converted-01.png');
    this.graphic.but.title = 'Gingersoft格言';
    this.graphic.but.content = '用研發人員的複雜，成就用戶的簡單！';
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      if (w < 700) {
        this.window.type = '600';
        this.graphic.height = (w * 0.8 - 40) * 0.8;
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.graphic.height = (w * 0.8 - 40) * 0.8;
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
        this.window.class_ = '';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
